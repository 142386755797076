import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import api from '../../services/api';

export default function AutoCompleteCity({ codigouf, value, setValue, next = null, ...rest }) {
    const [options, setOptions] = useState([]);

    // eslint-disable-next-line
    useEffect(() => loadCity(), [codigouf]);
    // eslint-disable-next-line
    useEffect(() => loadValue(), [value, options]);

    async function loadCity() {
        // if(value && value.codigouf) setValue(null);
        const response  = await api.get(`/dados/listCidades/${codigouf}`);
        setOptions(response.data);
    }

    function loadValue() {
        console.log(value);
        if(value){
            if(!value.cidades && options.length > 0){
                const newValue = options.filter((city) => String(city.municipio) === String(value));
                if(newValue[0]) setValue(newValue[0]);
            }
        }
    }

    function nextInput(event) {
        if(next){
            if(event.key === 'Enter'){
                var element = document.querySelector(`#${next}`);
                if(element) element.focus();
            }
        }
    }

    const useStyles = makeStyles((theme) => ({
        root: {
            "& .MuiOutlinedInput-root": {
                "& fieldset": {
                    border: `2px solid #000`,
                },
                "& input": {
                    fontWeight: 'bold',
                }
            }
        }
    }));

    const classes = useStyles();

    return (
        <Autocomplete
            value={(value) ? value : null}
            onChange={(event, newValue) => {
                console.log(newValue);
                setValue(newValue);
            }}
            onKeyPress={nextInput}
            options={options}
            getOptionLabel={(option) => option.cidades}
            fullWidth
            id="cidade"
            renderInput={(params) => (
                <TextField {...params} label="CIDADE" {...rest} classes={{root: classes.root}} />
            )}
        />
    );
}
