import { useState, useEffect } from 'react';
import { FaTrash } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import qs from 'querystring';
import moment from 'moment/moment';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import Template from '../../components/Template/Template';
import Input from '../../components/Inputs/Input';
import Snack from '../../components/Alert/Snack';
import SelectValueForLabel from '../../components/Selects/SelectValueForLabel';
import SelectSimple from '../../components/Selects/SelectSimple';
import AutoCompleteCity from '../../components/Selects/AutoCompleteCity';
import SimpleBackdrop from '../../components/Loading/SimpleBackdrop';
import TableData from '../../components/Table/TableData';
import ModalPagamento from '../../components/Modal/ModalPagamento';
import ModalAssinatura from '../../components/Modal/ModalAssinatura';
import ModalLicense from '../../components/Modal/ModalLicense';
import ModalOptions from '../../components/Modal/ModalOptions';
import DrawerInfoParcela from '../../components/Drawer/DrawerInfoParcela';
import { AlertInfo, AlertConfirm } from '../../components/Alert/Alert';
import { arrayUfCode, arrayUfLabel } from '../../data/options';
import { maskCPF, maskCNPJ, maskNumber, maskCEP, maskFone, maskDinheiro, removeMask } from '../../services/mask';
import { displayMoney, typeSafe2Pay, labelMes } from '../../services/displayValue';
import { dateBrl, dateBrlHrs, dateUsd } from '../../services/date';
import api, { consultaCnpj, logout, safe2pay } from '../../services/api';

export default function CadastroEmpresa(props) {
    const { id_empresa } = props.location.state;
    const [jwt] = useState(sessionStorage.getItem('jwtRenove'));
    const [infoLogin] = useState(JSON.parse(sessionStorage.getItem('infoLogin')));
    const [openLoad, setOpenLoad] = useState(false);
    const [id, setId] = useState(null);
    const [razao, setRazao] = useState("");
    const [fantasia, setFantasia] = useState("");
    const [cnpj, setCnpj] = useState("");
    const [ie, setIe] = useState("");
    const [responsavel, setResponsavel] = useState("");
    const [cpf_responsavel, setCpf] = useState("");
    const [endereco, setEndereco] = useState("");
    const [bairro, setBairro] = useState("");
    const [numero, setNumero] = useState(0);
    const [complemento, setComplemento] = useState("");
    const [cep, setCep] = useState("");
    const [codigouf, setCodigoUf] = useState("15");
    const [uf, setUf] = useState("PA");
    const [cidade, setCidade] = useState("");
    const [telefone, setTelefone] = useState("");
    const [email, setEmail] = useState("");
    const [usa_fiscal, setUsaFiscal] = useState(0);
    const [situacao, setSituacao] = useState(1);
    const [maquinas, setMaquinas] = useState(1);
    const [valor_suporte, setValorSuporte] = useState("");
    const [data_adessao, setDataAdessao] = useState(dateUsd());
    const [dia_pagamento, setDiaPagamento] = useState(10);
    const [contabilidade, setContabilidade] = useState(0);
    const [revenda, setRevenda] = useState(0);
    const [arrCont, setArrCont] = useState([]);
    const [arrReven, setArrReven] = useState([]);
    const [isPage, setPage] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const [openModalAss, setOpenModalAss] = useState(false);
    const [openLicense, setOpenLicense] = useState(false);
    const [openOptions, setOpenOptions] = useState(false);
    const [arrOptions, setArrOptions] = useState([]);
    const [infoModal, setInfoModal] = useState(null);
    const [pagamentos, setPagamentos] = useState([]);
    const [licenses, setLicenses] = useState([]);
    const [logs, setLogs] = useState([]);
    const [backups, setBackups] = useState([]);
    const [situacao_origem, setSituacaoOrigem] = useState(1);
    const [openDrawerInfo, setOpenDrawerInfo] = useState(false);
    const [infoDrawer, setInfoDrawer] = useState(null);
    const [openSnack, setOpenSnack] = useState(false);
    const [msgSnack, setMsgSnack] = useState('');
    const history = useHistory();
    const [columns] = useState([
        { id: 'id_transaction', label: 'COD', minWidth: 40 },
        { id: 'type_cobranca', label: 'TIPO', minWidth: 40, format: typeSafe2Pay },
        { id: 'create_transaction', label: 'EMISSÃO', minWidth: 50, format: dateBrl },
        { id: 'vencimento', label: 'VENCIMENTO', minWidth: 50, format: dateBrl },
        { id: 'data_pagamento', label: 'PAGAMENTO', minWidth: 50, format: dateBrl },
        { id: 'valor_boleto', label: 'VALOR', minWidth: 50, format: displayMoney, align: 'right' },
        { id: 'fantasia', label: 'EMPRESA', minWidth: 170 },
    ]);
    const [columnsLicense] = useState([
        { id: 'periodo_license', label: 'PERIODO', minWidth: 50, format: labelMes },
        { id: 'valor_license', label: 'VALOR', minWidth: 40, format: displayMoney },
        { id: 'payment_license', label: 'PAGAMENTO', minWidth: 40, format: typeSafe2Pay },
        { id: 'data_inicio', label: 'DATA INICIO', minWidth: 50, format: dateBrl },
        { id: 'data_expire', label: 'DATA FIM', minWidth: 50, format: dateBrl },
    ]);

    async function loadDataUsers() {
        const response = await api.get(`/usuarios/listUsuarios/${infoLogin.id}/${jwt}`);
        if (response.status === 200) {
            if (response.data.invalidJwt) {
                logout();
            } else {
                const { data } = response;
                const newArr = data.filter(i => Number(i.contador) === 1)
                    .map(item => ({ value: item.id, label: item.usuario }));
                const newArr2 = data.filter(i => Number(i.revenda) === 1)
                    .map(item => ({ value: item.id, label: item.usuario }));
                setArrCont([{ value: 0, label: '---' }, ...newArr]);
                setArrReven([{ value: 0, label: '---' }, ...newArr2]);
                return true;
            }
        }
        return false;
    }

    async function loadDataCompany() {
        if(Number(id_empresa) > 0){
            const data = qs.stringify({id: id_empresa});
            const response = await api.post(`/empresas/infoCompany/${infoLogin.id}/${jwt}`, data);
            if (response.status === 200) {
                if (response.data.invalidJwt) {
                    logout();
                } else {
                    if (response.data && response.data.id) {
                        setInfoModal(response.data);
                        setId(response.data.id || null);
                        setRazao(response.data.razao || "");
                        setFantasia(response.data.fantasia || "");
                        setCnpj(response.data.cnpj || "");
                        setIe(response.data.ie || "");
                        setResponsavel(response.data.responsavel || "");
                        setCpf(response.data.cpf || "");
                        setEndereco(response.data.endereco || "");
                        setBairro(response.data.bairro || "");
                        setNumero(response.data.numero || 0);
                        setComplemento(response.data.complemento || "");
                        setCep(response.data.cep || "");
                        setCodigoUf(response.data.codigouf || "15");
                        setUf(response.data.uf || "PA");
                        setCidade(response.data.codigomunicipio || "");
                        setTelefone(response.data.telefone || "");
                        setEmail(response.data.email || "");
                        setUsaFiscal(Number(response.data.tipopessoa) || 0);
                        setSituacao(response.data.situacao || 1);
                        setSituacaoOrigem(response.data.situacao || 1);
                        setMaquinas(response.data.maquinas || 1);
                        setValorSuporte(response.data.valor_suporte || "");
                        setDataAdessao(response.data.data_adessao || dateUsd());
                        setDiaPagamento(response.data.dia_pagamento || 10);
                        setContabilidade(response.data.contabilidade || 0);
                        setRevenda(response.data.revenda || 0);
                    }
                }
            }
        }else{
            if(Number(infoLogin.revenda) > 0) setRevenda(infoLogin.id);
            let inputFocus = document.getElementById('cnpj');
            if(inputFocus) inputFocus.focus();
        }
    }

    async function loadData() {
        setOpenLoad(true);
        const users = await loadDataUsers();
        if (users) await loadDataCompany();
        setOpenLoad(false);
    }

    async function loadFinancas() {
        if (id) {
            setOpenLoad(true);
            const resp = await api.get(`/financeiro/listAcountCompany/${infoLogin.id}/${jwt}/${id}`);
            if (resp && resp.status === 200) setPagamentos(resp.data);
            else console.log(resp);
            const response = await api.get(`/license/listLicenseByCompany/${infoLogin.id}/${jwt}/${id}`);
            if(response && response.status === 200 &&response.data){
                if(response.data.success) setLicenses(response.data.data);
            }
            setOpenLoad(false);
        }
    }

    async function loadHistory() {
        if (id) {
            setOpenLoad(true);
            const res = await api.get(`/empresas/listLogs/${id}`);
            if (res && res.status === 200) setLogs(res.data);

            const back = await safe2pay.get(`/backups`);
            if (back && back.status === 200 && back.data) {
                if (back.data.error || back.data.err) {
                    //
                } else {
                    const arrBackups = back.data;
                    const cpfcnpj = cnpj.length > 0 ? cnpj : cpf_responsavel;
                    const backupCompany = arrBackups.filter(item => String(item.name).includes(removeMask(cpfcnpj)));
                    const sortBackup = backupCompany.sort((a, b) => {
                        return a.birthtime < b.birthtime ? -1 : a.birthtime > b.birthtime ? 1 : 0;
                    });
                    setBackups(sortBackup.reverse());
                }
            }
            setOpenLoad(false);
        }
    }

    async function downloadBackup(item) {
        if (item && item.name) {
            const fileName = String(item.name).split('.');
            window.open(`https://apinode.renovetecnologia.com/dowloadbackup?file=${fileName[0]}`, '_blank');
        }
    }

    function filterParcela(infoParcela, status) {
        if (status === 1) {
            if (Number(infoParcela.status_parcela) === 1 && moment(dateUsd()).isAfter(infoParcela.vencimento)) {
                return infoParcela;
            }
        } else if (status === 2) {
            if (Number(infoParcela.status_parcela) === 3) {
                return infoParcela;
            }
        } else if (status === 3) {
            if (Number(infoParcela.recebido) === 1) {
                return infoParcela;
            }
        } else if (status === 4) {
            if (Number(infoParcela.status_parcela) === 1 && moment(dateUsd()).isBefore(infoParcela.vencimento)) {
                return infoParcela;
            }
        } else if (status === 5) {
            if (Number(infoParcela.status_parcela) === 1 && dateUsd() === infoParcela.vencimento) {
                return infoParcela;
            }
        } else if (status === 6) {
            if (Number(infoParcela.status_parcela) === 2) {
                return infoParcela;
            }
        } else if (status === 7) {
            if (Number(infoParcela.status_parcela) === 7 && Number(infoParcela.recebido) === 0) {
                return infoParcela;
            }
        }
    }

    const isColor = (row) => {
        let color = '#000';
        if (filterParcela(row, 1)) color = "#a71a1a";
        else if (filterParcela(row, 2)) color = "#6bbd5b";
        else if (filterParcela(row, 3)) color = "#248fb2";
        else if (filterParcela(row, 5)) color = "#4d524e";
        else if (filterParcela(row, 6)) color = "#004805";
        else if (filterParcela(row, 7)) color = "#3c0263";
        return color;
    }

    const isColorLicense = (row) => {
        let color = '#000';
        if (moment(row.data_expire).isBefore(moment(dateUsd()))) color = "#6bbd5b";
        return color;
    }

    const handleDrawer = (info) => {
        setInfoDrawer(info);
        setOpenDrawerInfo(true);
    }

    async function deleteLicense(info) {
        setOpenOptions(false);
        AlertConfirm('', 'DELETAR LICENÇA', 'A Ação não poderá ser desfeita', 'DELETAR').then(async res => {
            if(res.isConfirmed){
                const data = qs.stringify({
                    id_empresa: id,
                    id_licences: info.id_licences
                });
                const response = await api.post(`/license/deleteLicenseCompany/${infoLogin.id}/${jwt}`, data);
                if(response && response.status === 200 && response.data){
                    console.log(response);
                    AlertInfo('success', 'LICENÇA EXCUIDA');
                    loadFinancas();
                }
            }
        });
    }

    const handleAlterLicense = (row) => {
        const data = [
            {
                clickFunction: deleteLicense,
                Icon: DeleteIcon,
                text: 'DELETAR LICENÇA',
                data: row
            }
        ];
        setArrOptions(data);
        setOpenOptions(true);
    }

    const handleSituacao = () => {
        if (Number(situacao) === 1) setSituacao(2);
        else setSituacao(1);
    }

    async function handleSubmit() {
        setOpenLoad(true);
        const data = {
            id,
            razao: razao.toUpperCase(),
            fantasia: fantasia.toUpperCase(),
            cnpj,
            ie: removeMask(ie),
            responsavel: responsavel.toUpperCase(),
            cpf_responsavel,
            endereco: endereco.toUpperCase(),
            bairro: bairro.toUpperCase(),
            numero,
            complemento: complemento.toUpperCase(),
            cep,
            uf,
            codigouf,
            cidade: (cidade && cidade.cidades) ? cidade.cidades : '',
            codigomunicipio: (cidade && cidade.municipio) ? cidade.municipio : '',
            telefone,
            email: email.toUpperCase(),
            usa_fiscal,
            situacao,
            maquinas,
            valor_suporte,
            data_adessao,
            dia_pagamento,
            contabilidade,
            revenda,
            historico: (situacao_origem !== situacao) ? `${situacao === 1 ? 'LIBERADO' : 'BLOQUEADO'} MANUALMENTE` : null,
        }

        const response = await api.post(`/empresas/setupEmpresa`, qs.stringify(data));
        console.log(response);
        if (response && response.status === 200 && response.data) {
            if(response.data.success){
                AlertInfo('success', 'DADOS GRAVADOS!').then(() => {
                    history.push('/empresas');
                });
            }else{
                console.log(response);
                setMsgSnack('FALHA AO SALVAR DADOS');
                setOpenSnack(true);
            }
        }
        setOpenLoad(false);
    }

    function inativeCompany() {
        AlertConfirm('', 'DELETAR EMPRESA DOS REGISTROS', '', 'DELETAR').then(async resp => {
            if (resp.isConfirmed) {
                setOpenLoad(true);
                const response = await api.post(`/empresas/inativeCompany`, qs.stringify({ id }));
                console.log(response);
                if (response && response.status === 200) {
                    AlertInfo('success', 'DADOS GRAVADOS!').then(() => {
                        history.push('/empresas');
                    });
                }
                setOpenLoad(true);
            }
        });
    }

    async function isExistCompany() {
        if (cnpj.length > 0 && !id) {
            setOpenLoad(true);
            const response = await api.post(`/empresas/isExistCompany`, qs.stringify({ cnpj }));
            if (response.status === 200 && response.data) {
                if (response.data.id) {
                    const txt = 'EMPRESA JÁ CONSTA NA BASE DE DADOS DA RENOVE. OS DADOS SERÃO IMPORTADOS';
                    AlertInfo('success', 'DADOS ENCONTRADOS', txt);
                    setId(response.data.id);
                    setRazao(response.data.razao);
                    setCnpj(response.data.cnpj);
                    setIe(response.data.ie);
                    setFantasia(response.data.fantasia);
                    setResponsavel(response.data.responsavel);
                    setCpf(response.data.cpf);
                    setEndereco(response.data.endereco);
                    setNumero(response.data.numero);
                    setBairro(response.data.bairro);
                    setComplemento(response.data.complemento);
                    setCodigoUf(response.data.codigouf);
                    setUf(response.data.uf);
                    setCidade(response.data.codigomunicipio);
                    setCep(response.data.cep);
                    setTelefone(response.data.telefone);
                    setEmail(response.data.email);
                    setDiaPagamento(String(response.data.dia_pagamento));
                    setRevenda(response.data.revenda);
                }else{
                    const cnpj2 = removeMask(cnpj);
                    try {
                        const response2 = await consultaCnpj.get(`/${cnpj2}`);
                        if(response2 && response2.status === 200 && response2.data){
                            const dataCompany = response2.data;
                            console.log(dataCompany);
                            if(dataCompany.status && dataCompany.status === 400){
                                setMsgSnack(dataCompany.detalhes);
                                setOpenSnack(true);
                            }else if(dataCompany.cnpj_raiz){
                                setRazao(dataCompany.razao_social);
                                setFantasia(dataCompany.estabelecimento.nome_fantasia);
                                setIe(dataCompany.estabelecimento.inscricoes_estaduais[0] ? dataCompany.estabelecimento.inscricoes_estaduais[0].inscricao_estadual : '');
                                setEndereco(dataCompany.estabelecimento.logradouro);
                                setNumero(dataCompany.estabelecimento.numero);
                                setBairro(dataCompany.estabelecimento.bairro);
                                setComplemento(dataCompany.estabelecimento.complemento ? dataCompany.estabelecimento.complemento : '');
                                setUf(dataCompany.estabelecimento.estado.sigla);
                                setCidade(dataCompany.estabelecimento.cidade.ibge_id);
                                setCep(dataCompany.estabelecimento.cep);
                                setMsgSnack("DADOS LOCALIZADOS POR CONSULTA");
                                setOpenSnack(true);
                            }
                        }
                    } catch (error) {
                        console.log(error);
                        setMsgSnack('Não foi possivel consultar CNPJ');
                        setOpenSnack(true);
                    }
                }
            }
            setOpenLoad(false);
        }
    }

    // eslint-disable-next-line
    useEffect(() => loadData(), []);

    useEffect(() => {
        if (uf && arrayUfCode) {
            const fill = arrayUfCode.filter(i => i.label === uf);
            if (fill && fill.length === 1) setCodigoUf(fill[0].value);
        }
        // eslint-disable-next-line
    }, [uf]);

    useEffect(() => {
        if (isPage === 1) loadFinancas();
        else if (isPage === 2) loadHistory();
        // eslint-disable-next-line
    }, [isPage]);

    return (
        <Template menuActive="empresas">
            <Snack
                open={openSnack}
                setOpen={setOpenSnack}
                message={msgSnack}
                time={3000}
            />
            <Paper square style={{ marginBottom: 8 }}>
                <Tabs
                    value={isPage}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={(event, newValue) => setPage(newValue)}
                    aria-label="disabled tabs example"
                    centered
                >
                    <Tab label="DADOS" />
                    <Tab label="PAGAMENTO" />
                    <Tab label="HISTORICO" />
                </Tabs>
            </Paper>
            <DrawerInfoParcela
                info={infoDrawer}
                setInfo={setInfoDrawer}
                state={openDrawerInfo}
                setState={setOpenDrawerInfo}
                load={setOpenLoad}
                allRows={pagamentos}
                setAllRows={setPagamentos}
                loadData={loadFinancas}
            />

            {isPage === 0 && (
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Paper elevation={3} style={{ padding: 10 }}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={3}>
                                    {id && (
                                        <Input
                                            size="small"
                                            label="ID EMPRESA"
                                            value={id}
                                            onChange={() => { }}
                                        />
                                    )}
                                </Grid>
                                <Grid item xs={false} sm={6}></Grid>
                                <Grid item xs={12} sm={3}>
                                    {id && (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={() => inativeCompany()}
                                        >
                                            <FaTrash />&nbsp; DELETAR
                                        </Button>
                                    )}
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <Input
                                        id="cnpj"
                                        size="small"
                                        label="CNPJ"
                                        value={cnpj}
                                        onChange={(e) => maskCNPJ(e, setCnpj)}
                                        onBlur={isExistCompany}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Input
                                        size="small"
                                        label="RAZÃO SOCIAL"
                                        value={razao}
                                        onChange={(e) => setRazao(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Input
                                        size="small"
                                        label="FANTASIA"
                                        value={fantasia}
                                        onChange={(e) => setFantasia(e.target.value)}
                                    />
                                </Grid>
                                
                                <Grid item xs={12} sm={4}>
                                    <Input
                                        size="small"
                                        label="INSCRIÇÃO ESTADUAL"
                                        value={ie}
                                        onChange={(e) => maskNumber(e, setIe)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Input
                                        size="small"
                                        label="RESPONSAVEL"
                                        value={responsavel}
                                        onChange={(e) => setResponsavel(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Input
                                        size="small"
                                        label="CPF DO RESPONSAVEL"
                                        value={cpf_responsavel}
                                        onChange={(e) => maskCPF(e, setCpf)}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <Input
                                        size="small"
                                        label="ENDEREÇO"
                                        value={endereco}
                                        onChange={(e) => setEndereco(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Input
                                        size="small"
                                        label="BAIRRO"
                                        value={bairro}
                                        onChange={(e) => setBairro(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <Input
                                        size="small"
                                        label="NUMERO"
                                        value={numero}
                                        onChange={(e) => maskNumber(e, setNumero)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Input
                                        size="small"
                                        label="COMPLEMENTO"
                                        value={complemento}
                                        onChange={(e) => setComplemento(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <SelectSimple
                                        label="UF"
                                        value={uf}
                                        setValue={setUf}
                                        options={arrayUfLabel}
                                        variant="outlined"
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <AutoCompleteCity
                                        codigouf={codigouf}
                                        value={cidade}
                                        setValue={setCidade}
                                        variant="outlined"
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Input
                                        size="small"
                                        label="CEP"
                                        value={cep}
                                        onChange={(e) => maskCEP(e, setCep)}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <Input
                                        size="small"
                                        label="EMAIL"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Input
                                        size="small"
                                        label="TELEFONE"
                                        value={telefone}
                                        onChange={(e) => maskFone(e, setTelefone)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Input
                                        type="date"
                                        label="DATA ADESSÃO"
                                        value={data_adessao}
                                        onChange={(e) => setDataAdessao(e.target.value)}
                                        size="small"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Input
                                        type="number"
                                        label="DIA PAGAMENTO"
                                        value={dia_pagamento}
                                        onChange={e => setDiaPagamento(e.target.value)}
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Input
                                        type="number"
                                        size="small"
                                        label="MAQUINAS"
                                        value={maquinas}
                                        onChange={(e) => setMaquinas(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Input
                                        type="number"
                                        size="small"
                                        label="VALOR SUPORTE"
                                        value={valor_suporte}
                                        onChange={(e) => maskDinheiro(e, setValorSuporte)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <SelectValueForLabel
                                        label="USA FISCAL"
                                        value={usa_fiscal}
                                        setValue={setUsaFiscal}
                                        options={[{ value: 0, label: 'SIM' }, { value: 1, label: 'NÃO' }]}
                                        variant="outlined"
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <SelectValueForLabel
                                        label="CONTABILIDADE"
                                        value={contabilidade}
                                        setValue={setContabilidade}
                                        options={arrCont}
                                        variant="outlined"
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <SelectValueForLabel
                                        label="REVENDA"
                                        value={revenda}
                                        setValue={setRevenda}
                                        options={arrReven}
                                        variant="outlined"
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} sm={3}>
                                    {id && (
                                        <Button
                                            variant="contained"
                                            color={Number(situacao) === 2 ? 'primary' : ''}
                                            className={Number(situacao) === 1 ? 'successColor' : ''}
                                            fullWidth
                                            onClick={handleSituacao}
                                        >
                                            {Number(situacao) === 1 ? 'ATIVO' : 'BLOQUEADO'}
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    <Grid item xs={false} sm={4}></Grid>
                    <Grid item xs={12} sm={4}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={handleSubmit}
                        >
                            SALVAR
                        </Button>
                    </Grid>
                    <Grid item xs={false} sm={4}></Grid>
                </Grid>
            )}
            {isPage === 1 && (
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={4}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => setOpenModal(true)}
                        >
                            EMITIR NOVA COBRANÇA
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => setOpenModalAss(true)}
                        >
                            ASSINATURA
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => setOpenLicense(true)}
                        >
                            EMITIR LICENÇA
                        </Button>
                    </Grid>
                    {licenses.length > 0 && (
                        <>
                            <Grid item xs={12}>
                                <h3 style={{textAlign:'center'}}>LICENÇAS</h3>
                            </Grid>
                            <Grid item xs={12}>
                                <TableData
                                    columns={columnsLicense}
                                    rows={licenses}
                                    clickFunction={handleAlterLicense}
                                    isColor={isColorLicense}
                                    vh="20vh"
                                />
                            </Grid>
                        </>
                    )}
                    {pagamentos.length > 0 && (
                        <>
                            <Grid item xs={12}>
                                <h3 style={{textAlign:'center'}}>COBRANÇAS</h3>
                            </Grid>
                            <Grid item xs={12}>
                                <TableData
                                    columns={columns}
                                    rows={pagamentos}
                                    clickFunction={handleDrawer}
                                    isColor={isColor}
                                    vh={licenses.length > 0 ? "40vh" : "60vh"}
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
            )}

            {isPage === 2 && (
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <h2>LOGS</h2>
                    </Grid>
                    <Grid item xs={12}>
                        <TableData
                            columns={[
                                { id: 'data_log', label: 'DATA', minWidth: 50, format: dateBrlHrs },
                                { id: 'log', label: 'LOG', minWidth: 170 },
                            ]}
                            rows={logs}
                            clickFunction={() => { }}
                            vh="30vh"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <h2>BACKUPS</h2>
                    </Grid>
                    <Grid item xs={12}>
                        <TableData
                            columns={[
                                { id: 'birthtime', label: 'DATA', minWidth: 100, format: dateBrlHrs },
                                { id: 'name', label: 'ARQUIVO', minWidth: 170 },
                            ]}
                            rows={backups}
                            clickFunction={downloadBackup}
                            vh="30vh"
                        />
                    </Grid>
                </Grid>
            )}
            <SimpleBackdrop open={openLoad} />
            <ModalPagamento open={openModal} setOpen={setOpenModal} info={infoModal} load={loadFinancas} />
            <ModalAssinatura
                open={openModalAss}
                setOpen={setOpenModalAss}
                id_empresa={id}
                email={email}
                name={razao.length > 0 ? razao : responsavel}
                doc={cnpj.length > 0 ? cnpj : cpf_responsavel}
                endereco={endereco}
                numero={numero}
                bairro={bairro}
                cep={cep}
                complemento={complemento}
                codigomunicipio={cidade && cidade.municipio ? cidade.municipio : cidade}
                openLoad={setOpenLoad}
            />
            <ModalLicense
                info={infoModal}
                open={openLicense}
                setOpen={setOpenLicense}
            />
            <ModalOptions
                open={openOptions}
                setOpen={setOpenOptions}
                list={arrOptions}
                title="OPÇÕES DA LICENÇA"
            />
        </Template>
    );
}