import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import md5 from 'md5';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Template from '../../components/Template/Template';
import TableData from '../../components/Table/TableData';
import SimpleBackdrop from '../../components/Loading/SimpleBackdrop';
import Input from '../../components/Inputs/Input';
import { isColor } from '../../components/Labels/LabelCod1';
import api, { apiEmissor } from '../../services/api';
import { removeMask } from '../../services/displayValue';

import DrawerRelRevenda from '../Revenda/DrawerRelRevenda';

export default function Empresas() {
    const [infoLogin] = useState(JSON.parse(sessionStorage.getItem('infoLogin')));
    const [jwt] = useState(sessionStorage.getItem('jwtRenove'));
    const [openLoad, setOpenLoad] = useState(false);
    const [allRows, setAllRows] = useState([]);
    const [rows, setRows] = useState([]);
    const [origem, setOrigem] = useState(2);
    const [busca, setBusca] = useState('');
    const [drawerRel, openDrawerRel] = useState(false);
    const [columns, setColumns] = useState([
        { id: 'id', label: 'CODIGO', minWidth: 50 },
        { id: 'fantasia', label: 'EMPRESA', minWidth: 170 },
        { id: 'responsavel', label: 'RESPONSAVEL', minWidth: 100 },
        { id: 'situacao', label: 'STATUS', minWidth: 50, format: isColor },
        { id: 'dia_pagamento', label: 'PAGAMENTO', minWidth: 50 },
    ]);
    const history = useHistory();

    function logout() {
        sessionStorage.removeItem('infoLogin');
        sessionStorage.removeItem('jwtRenove');
        history.push('/');
    }

    async function loadData() {
        setOpenLoad(true);
        const response = await api.get(`/empresas/listAllByRevenda/${infoLogin.id}/${jwt}/${infoLogin.id}`);
        console.log(response);
        if(response.status === 200){
            if(response.data.invalidJwt){
                logout();
            }else{
                setColumns([
                    { id: 'id', label: 'CODIGO', minWidth: 50 },
                    { id: 'fantasia', label: 'EMPRESA', minWidth: 170 },
                    { id: 'responsavel', label: 'RESPONSAVEL', minWidth: 100 },
                    { id: 'situacao', label: 'STATUS', minWidth: 50, format: isColor },
                    { id: 'dia_pagamento', label: 'PAGAMENTO', minWidth: 50 },
                ]);
                setRows(response.data);
                setAllRows(response.data);
            }
        }
        setOrigem(2);
        setOpenLoad(false);
    }

    async function loadSite() {
        setOpenLoad(true);
        console.log(infoLogin.cod_site);
        const response = await apiEmissor.get(`/empresas/loadEmpresas/${md5(infoLogin.id)}`);
        console.log(response);
        if(response && response.status === 200 && response.data){
            const { data } = response;
            if(data.length > 0){
                let formatData = data.map(i => ({...i, situacao: i.acesso, isSite: true}));
                setColumns([
                    { id: 'id', label: 'CODIGO', minWidth: 50 },
                    { id: 'fantasia', label: 'EMPRESA', minWidth: 170 },
                    { id: 'responsavel', label: 'RESPONSAVEL', minWidth: 100 },
                    { id: 'situacao', label: 'STATUS', minWidth: 50, format: isColor },
                    { id: 'limite', label: 'LIMITE', minWidth: 50 },
                ]);
                setRows(formatData);
                setAllRows(formatData);
            }else{
                setAllRows([]);
                setRows([]);
            }
        }
        setOpenLoad(false);
    }

    // eslint-disable-next-line
    useEffect(() => loadData(), []);

    function handleCadastro(json = {}) {
        const pathname = (json && json.isSite) ? '/cadastroempresasite' : '/cadastroempresa';
        const state = (json && json.isSite) ? { infoEmpresa: json } : { id_empresa: json.id || 0 };
        const location = {
            pathname,
            state,
        }
        history.push(location);
    }

    function handleOrigem(cod){
        setOrigem(cod);
        if(cod === 1) loadSite();
        if(cod === 2) loadData();
    }

    function filterSit(cod) {
        if(cod === 3) setRows(allRows);
        else setRows(allRows.filter(emp => Number(emp.situacao) === cod));
    }

    function buscaCompany(emp) {
        const b = busca.toUpperCase();
        if(Number(emp.id) === Number(b)) return emp;
        else if(String(emp.razao).includes(b)) return emp;
        else if(String(emp.fantasia).includes(b)) return emp;
        else if(removeMask(emp.cnpj).includes(removeMask(b))) return emp;
    }

    function handleBusca(event) {
        if(event.key === 'Enter'){
            if(busca.length > 0){
                const filter = allRows.filter(emp => buscaCompany(emp));
                setRows(filter);
            }else{
                setRows(allRows);
            }
        }
    }

    return(
        <Template menuActive="empresas">
            <Grid container spacing={2}>
                <Grid item xs={12} sm={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => handleCadastro()}
                    >
                        ADICIONAR
                    </Button>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Button
                        variant="contained"
                        color="secondary"
                        fullWidth
                        onClick={() => openDrawerRel(true)}
                    >
                        RELATORIOS
                    </Button>
                </Grid>
                <Grid item xs={false} sm={4}></Grid>
                <Grid item xs={12} sm={4}>
                    <Input 
                        size="small"
                        label="BUSCAR"  
                        value={busca}
                        onChange={e => setBusca(e.target.value)}
                        onKeyUp={handleBusca}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TableData
                        columns={columns}
                        rows={rows}
                        clickFunction={handleCadastro}
                        vh="65vh"
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <ButtonGroup color="primary" fullWidth aria-label="outlined primary button group" >
                        <Button
                            variant={origem === 1 ? "contained" : "outlined"}
                            onClick={() => handleOrigem(1)}
                        >
                            SITE
                        </Button>
                        <Button
                            variant={origem === 2 ? "contained" : "outlined"}
                            onClick={() => handleOrigem(2)}
                        >
                            ERP
                        </Button>
                    </ButtonGroup>
                </Grid>
                <Grid item xs={false} sm={2}></Grid>
                <Grid item xs={12} sm={6}>
                    {origem === 1 && (
                        <ButtonGroup color="primary" fullWidth aria-label="outlined primary button group" >
                            <Button 
                                variant="contained" 
                                className='infoColor' 
                                onClick={() => setRows(allRows.filter(emp => Number(emp.situacao) === 1 && Number(emp.limite) > 0))}
                            >
                                LIMIT. {allRows.filter(emp => Number(emp.situacao) === 1 && Number(emp.limite) > 0).length}
                            </Button>
                            <Button 
                                variant="contained" 
                                className='successColor' 
                                onClick={() => setRows(allRows.filter(emp => Number(emp.situacao) === 1 && Number(emp.limite) === 0))}
                            >
                                ATIVOS {allRows.filter(emp => Number(emp.situacao) === 1 && Number(emp.limite) === 0).length}
                            </Button>
                            <Button variant="contained" color="primary" onClick={() => filterSit(2)}>
                                BLOQ. {allRows.filter(emp => Number(emp.situacao) === 2).length}
                            </Button>
                            <Button variant="contained" color='secondary'onClick={() => filterSit(3)}>
                                TOTAL {allRows.length}
                            </Button>
                        </ButtonGroup>
                    )}
                    {origem === 2 && (
                        <ButtonGroup color="primary" fullWidth aria-label="outlined primary button group" >
                            <Button variant="contained" className='successColor' onClick={() => filterSit(1)}>
                                ATIVOS {allRows.filter(emp => Number(emp.situacao) === 1).length}
                            </Button>
                            <Button variant="contained" color="primary" onClick={() => filterSit(2)}>
                                BLOQ. {allRows.filter(emp => Number(emp.situacao) === 2).length}
                            </Button>
                            <Button variant="contained" color='secondary'onClick={() => filterSit(3)}>
                                TOTAL {allRows.length}
                            </Button>
                        </ButtonGroup>
                    )}
                </Grid>
            </Grid>
            <SimpleBackdrop open={openLoad} />
            <DrawerRelRevenda
                state={drawerRel}
                setState={openDrawerRel}
                origem={infoLogin.id}
            />
        </Template>
    );
}