import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FaDownload, FaFilter, FaWindows, FaGlobeAmericas } from 'react-icons/fa';
import qs from 'querystring';
import moment from 'moment';
import md5 from 'md5';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Input from '../../components/Inputs/Input';
import Template from '../../components/Template/Template';
import SimpleBackdrop from '../../components/Loading/SimpleBackdrop';
import SelectValueForLabel from '../../components/Selects/SelectValueForLabel';
import { AlertInfo } from '../../components/Alert/Alert';
import { removeMask } from '../../services/mask';
import api, { speed, spededURL, apiEmissor } from '../../services/api';
import './xml.css';

export default function Xml() {
    const [infoLogin] = useState(JSON.parse(sessionStorage.getItem('infoLogin')));
    const [jwt] = useState(sessionStorage.getItem('jwtRenove'));
    const [dataMes, setDataMes] = useState(moment().subtract(1, 'month').format('YYYY-MM'));
    const [companys, setCompanys] = useState([]);
    const [allRows, setAllRows] = useState([]);
    const [rows, setRows] = useState([]);
    const [busca, setBusca] = useState("");
    const [filtro, setFiltro] = useState("1");
    const [isBusca, setIsBusca] = useState(true);
    const [openLoad, setOpenLoad] = useState(false);
    const history = useHistory();

    async function loadXml() {
        if(dataMes.length > 0){
            setOpenLoad(true);
            const dataBusca = dataMes.split('-');
            const site = await loadXmlSite();
            const activeSite = site.filter(emp => emp.cnpj);
            const data = qs.stringify({lote: JSON.stringify([...companys, ...activeSite]), data: `${dataBusca[1]}-${dataBusca[0]}`});
            const response = await speed.post(`/xml/getLoteFilesXml`, data);
            if(response && response.status === 200 && response.data){
                setAllRows(response.data);
                setRows(response.data);
                if(busca.length > 0) setBusca('');
                if(filtro !== "1") setFiltro("1");
            }
            setOpenLoad(false);
        }
    }

    async function loadXmlSite() {
        const response = await apiEmissor.get(`/empresas/loadEmpresas/${md5(infoLogin.id)}`);
        console.log(response);
        if(response && response.status === 200 && response.data){
            if(response.data[0] && response.data[0].id) return response.data;
        }
        return [];
    }

    async function loadData() {
        setOpenLoad(true);
        const response = await api.get(`/empresas/listAll/${infoLogin.id}/${jwt}`);
        console.log(response);
        if(response.status === 200){
            if(!response.data.invalidJwt){
                const { data } = response;
                const isFiscal = data.filter(emp => Number(emp.tipopessoa) === 0);
                let emps = isFiscal;
                if(Number(infoLogin.contador) === 1 && Number(infoLogin.revenda) === 1){
                    emps = isFiscal.filter(emp => Number(emp.contabilidade) === Number(infoLogin.id) || Number(emp.revenda) === Number(infoLogin.id));
                }else if(Number(infoLogin.contador) === 1){
                    emps = isFiscal.filter(emp => Number(emp.contabilidade) === Number(infoLogin.id));
                }else if(Number(infoLogin.revenda) === 1){
                    emps = isFiscal.filter(emp => Number(emp.revenda) === Number(infoLogin.id));
                }
                setCompanys(emps);
            }
        }
        setOpenLoad(false);
    }

    function loadFiltro() {
        if(!isBusca){
            let fill = [];
            switch (filtro) {
                case "2":
                    fill = allRows.filter(emp => emp.nfe.length === 0 && emp.nfce.length === 0);
                    break;
                case "3":
                    fill = allRows.filter(emp => emp.nfe.length > 0 || emp.nfce.length > 0);
                    break;
                case "4":
                    fill = allRows.filter(emp => emp.nfe.length > 0 && emp.nfce.length === 0);
                    break;
                case "5":
                    fill = allRows.filter(emp => emp.nfce.length > 0 && emp.nfe.length === 0);
                    break;
                case "6":
                    fill = allRows.filter(emp => emp.nfce.length > 0 && emp.nfe.length > 0);
                    break;
            
                default:
                    fill = allRows;
                    break;
            }
            setRows(fill);
        }
    }

    async function downloadXmlCompany(emp) {
        if(emp.nfe.length === 0 && emp.nfce.length === 0){
            AlertInfo('error', 'XML VAZIO','NÃO HÁ ARQUIVOS PARA DOWNLOAD');
        }else{
            setOpenLoad(true);
            const splitData = String(dataMes).split('-');
            const data = qs.stringify({
                cnpj: removeMask(emp.company.cnpj),
                razao: emp.company.razao,
                data: `${splitData[1]}-${splitData[0]}`,
            });
            const response = await speed.post(`/xml/createzipFileLoteXml`, data);
            console.log(response);
            if(response && response.status === 200){
                if(response.data && response.data.filename){
                    const url = `${spededURL}/xml/baixarLoteXml/${response.data.filename}`;
                    window.open(url, '_blank');
                }
            }
            setTimeout(() => {
                setOpenLoad(false);
            }, 2000);
        }
    }

    async function downloadAll() {
        const isXml = allRows.filter(e => e.nfe.length > 0 || e.nfce.length > 0);
        if(isXml.length === 0){
            AlertInfo('error', 'XML VAZIO','NÃO HÁ ARQUIVOS PARA DOWNLOAD');
        }else{
            setOpenLoad(true);
            const splitData = String(dataMes).split('-');
            const data = qs.stringify({
                data: `${splitData[1]}-${splitData[0]}`,
                nome_usuario: infoLogin.usuario,
                companys: JSON.stringify(isXml.map(e => ({cnpj: e.company.cnpj, razao: e.company.razao})))
            });
            const response = await speed.post(`/xml/createzipFileLoteAllCompanysXml`, data);
            console.log(response);
            if(response && response.status === 200){
                if(response.data && response.data.filename){
                    const url = `${spededURL}/xml/baixarLoteXml/${response.data.filename}`;
                    window.open(url, '_blank');
                }
            }
            setTimeout(() => {
                setOpenLoad(false);
            }, 2000);
        }
    }

    function handleBusca(event) {
        if(event.key === 'Enter'){
            if(busca.length > 0){
                const b = busca.toUpperCase();
                // eslint-disable-next-line
                const fill = allRows.filter(emp => {
                    if(String(emp.company.razao).includes(b)) return emp;
                    if(String(emp.company.fantasia).includes(b)) return emp;
                    if(removeMask(emp.company.cnpj) === removeMask(b)) return emp;
                });
                setRows(fill);
            }else{
                setRows(allRows);
            }
        }
    }

    function configArr(item, type) {
        const filename = String(item.file).split('/');
        const file = filename[filename.length - 1];
        return { ...item, file, type }
    }

    function handleDetails(emp) {
        const nfe = emp.nfe.map(i => configArr(i, 'NFe'));
        const nfce = emp.nfce.map(i => configArr(i, 'NFCe'));
        const company = emp.company;
        const pathname = '/detalhesxml';
        const location = {
            pathname,
            state: { infoXml: {company, nfe, nfce, dataMes} },
        }
        history.push(location);
    }

    // eslint-disable-next-line
    useEffect(() => loadFiltro(), [filtro]);

    // eslint-disable-next-line
    useEffect(() => loadXml(), [companys, dataMes]);

    // eslint-disable-next-line
    useEffect(() => loadData(), []);

    return(
        <Template menuActive="xml">
            <SimpleBackdrop open={openLoad} />
            <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                    <Input 
                        fullWidth
                        type="month"
                        label="MÊS" 
                        variant="outlined"
                        size='small'
                        value={dataMes}
                        onChange={(e) => setDataMes(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Button
                        variant="contained"
                        color="secondary"
                        fullWidth
                        onClick={() => downloadAll()}
                    >
                        <FaDownload /> &nbsp;BAIXAR TUDO
                    </Button>
                </Grid>

                <Grid item xs={false} sm={2} />

                <Grid item xs={12} sm={4}>
                    <div className='containerBusca'>
                        <div 
                            className='containerIconBusca'
                            onClick={() => setIsBusca(!isBusca)}
                        >
                            <FaFilter />
                        </div>
                        {isBusca && (
                            <Input 
                                fullWidth
                                type="text"
                                label="BUSCAR" 
                                variant="outlined"
                                size='small'
                                value={busca}
                                onChange={e => setBusca(e.target.value)}
                                onKeyUp={handleBusca}
                            />
                        )}
                        {!isBusca && (
                            <SelectValueForLabel 
                                label="FILTRO"
                                value={filtro}
                                setValue={setFiltro}
                                options={[
                                    { value: "1", label: 'TODOS' }, 
                                    { value: "2", label: 'SEM EMISSÃO' },
                                    { value: "3", label: 'SOMENTE COM EMISSÃO' },
                                    { value: "4", label: 'SOMENTE COM NFE' },
                                    { value: "5", label: 'SOMENTE COM NFCE' },
                                    { value: "6", label: 'SOMENTE COM NFE & NFCE' },
                                ]} 
                                variant="outlined"
                                size="small"
                            />
                        )}
                    </div>
                </Grid>
                <Grid item xs={12}></Grid>
                {rows.map(emp => (
                    <Grid item xs={12} sm={4} key={emp.id}>
                        <Paper elevation={3} style={{padding: 10}}>
                            <div className='containerInPaper'>
                                <div className='iconPlatformPaper'>
                                    {emp.company.situacao ? <FaWindows /> : <FaGlobeAmericas />}
                                </div>
                                <div className='containerHeaderPaper' onClick={() => handleDetails(emp)}>
                                    <span>{emp.company.razao}</span>
                                    <span>{emp.company.cnpj}</span>
                                    <span>{emp.company.fantasia}</span>
                                </div>
                                
                                <div className='containerFooterPaper'>
                                    <div>
                                        <div style={{backgroundColor: (emp.nfe.length > 0)?'#6bbd5b':'#a71a1a'}}>
                                            <span>NFe: {emp.nfe.length}</span>
                                        </div>
                                        <div style={{backgroundColor: (emp.nfce.length > 0)?'#6bbd5b':'#a71a1a'}}>
                                            <span>NFCe: {emp.nfce.length}</span>
                                        </div>
                                    </div>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => downloadXmlCompany(emp)}
                                    >
                                        <FaDownload /> &nbsp; BAIXAR
                                    </Button>
                                </div>
                            </div>
                        </Paper>
                    </Grid>
                ))}
            </Grid>
        </Template>
    );
}